<template>
  <div class="navigation-links">
    <router-link
      :to="{ name: 'Projects' }"
      class="link"
      :class="{
        'partially-active': $route.path.includes('/projects/')
      }"
    >
      {{ $t('projects') }}
    </router-link>
    <router-link
      :to="{ name: 'Team' }"
      class="link"
    >
      {{ $t('team') }}
    </router-link>
    <router-link
      :to="{ name: 'Tech' }"
      class="link"
    >
      {{ $t('tech') }}
    </router-link>
    <router-link
      :to="{ name: 'Career' }"
      class="link"
    >
      {{ $t('jobs') }}
    </router-link>
    <router-link
      :to="{ name: 'Contacts' }"
      class="link"
    >
      {{ $t('contacts') }}
    </router-link>

    <button
      class="link accent"
      @click="openContactForm"
    >
      <span>
        {{ $t('discuss_project') }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    openContactForm() {
      this.$store.commit('setContactFormOpen', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-links {
  display: flex;
  align-items: center;

  .link {
    padding: 16px;
    font: $font-text-18;

    &.router-link-active, &.partially-active {
      text-decoration-line: underline;
      text-underline-offset: 10px;
      text-decoration-color: $orange;
    }

    &.router-link-active {
      text-decoration-style: solid;
    }

    &.partially-active {
      text-decoration-style: dotted;
    }

    &.accent {
      border: 1px solid transparent;
      border-radius: 10px;

      @include themed() {
        background:
          linear-gradient(t('bg'), t('bg')) padding-box,
          $grad-orange border-box;
      }

      span {
        @include grad-text($grad-orange);
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .navigation-links {
    flex-direction: column;

    .link.accent {
      margin-top: 40px;
      padding: 12px 18px;
    }
  }
}
</style>
