<template>
  <button
    class="switch"
    @click="toggleTheme"
    aria-label="Toggle dark theme"
  >
    <div
      class="icon"
      :class="{ darkTheme }"
    >
      <img
        src="@/assets/icons/sun_active.svg"
        alt="Sun glowing"
      >
      <img
        src="@/assets/icons/sun_dark.svg"
        alt="Sun dark"
      >
    </div>

    <div class="border"/>

    <div
      class="icon moon"
      :class="{ darkTheme }"
    >
      <img
        src="@/assets/icons/moon_active.svg"
        alt="Moon glowing"
      >
      <img
        src="@/assets/icons/moon_dark.svg"
        alt="Moon dark"
      >
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['theme']),
    darkTheme() {
      return this.theme === 'dark';
    },
  },
  methods: {
    setDarkTheme() {
      document.body.classList.remove('theme--light');
      document.body.classList.add('theme--dark');
      this.$store.commit('setTheme', 'dark');
    },
    setLightTheme() {
      document.body.classList.remove('theme--dark');
      document.body.classList.add('theme--light');
      this.$store.commit('setTheme', 'light');
    },
    toggleTheme() {
      if (document.body.classList.contains('theme--light')) {
        this.setDarkTheme();
      } else {
        this.setLightTheme();
      }
    },
  },
  mounted() {
    if (this.$store.state.theme === 'light') {
      this.setLightTheme();
    } else {
      this.setDarkTheme();
    }
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  border: 1px solid $light-blue;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  overflow: hidden;
  min-width: min-content;
  width: min-content;
  min-height: 32px;
  box-sizing: border-box;

  .icon {
    width: 20px;
    height: 20px;
    padding: 0 10px;
    transition: transform .5s;

    &.moon {
      transform: translateY(calc(-100% - 7px));

      &.darkTheme {
        transform: translateY(0);
      }
    }

    &.darkTheme {
      transform: translateY(calc(-100% - 7px));
    }
  }

  .border {
    position: absolute;
    width: 50%;
    height: calc(100% - 10px);
    border-right: 1px solid $light-blue;
  }
}
</style>
