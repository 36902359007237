<template>
  <div
    class="hero"
    id="hero"
    :style="{
      height: `${height}px`,
    }"
  >
    <Background />
    <Fidgets />

    <div
      class="text"
    >
      <h1>
        {{ $t('home.hero_text') }}
      </h1>
      <router-link
        :to="{ name: 'Projects' }"
        class="see-projects"
      >
        <span>
          {{ $t('home.see_projects') }}
        </span>
        <img
          src="@/assets/icons/arrow.svg"
          alt=""
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import Background from './Background.vue';
import Fidgets from './Fidgets.vue';

export default {
  name: 'Hero',
  components: {
    Background,
    Fidgets,
  },
  data() {
    return {
      height: undefined,
    };
  },
  mounted() {
    if (!this.$store.state.desktop) {
      this.height = window.innerHeight;
    }
  },
};
</script>

<style lang="scss" scoped>
.hero {
  text-align: center;
  height: calc(100vh + 1px);
  padding-top: 100px;
  box-sizing: border-box;

  .bg, #fidgets {
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .text {
    z-index: 20;
    display: inline-block;
    position: relative;
    max-width: 746px;
    margin: 126px 50px 424px 50px;

    h1 {
      font-weight: 500;
      font-size: 60px;
      line-height: 118%;
      margin-bottom: 32px;
      user-select: none;
    }

    .see-projects {
      font: $font-h-22;
      width: max-content;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        @include grad-text($grad-orange);
      }

      img {
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .hero {
    padding-top: 80px;

    .text {
      margin-top: 87px;

      h1 {
        font: $font-h-36;
      }

      .see-projects {
        font: $font-h-20;
      }
    }
  }
}
</style>
