import { createApp } from 'vue';
import VueNumber from 'vue-number-animation';
import { ObserveVisibility } from 'vue-observe-visibility';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './localization';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.use(VueNumber);

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance; // eslint-disable-line
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});

app.mount('#app');
