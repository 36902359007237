<template>
  <div class="links">
    <a
      href="https://t.me/Artem_Haritonov"
      target="_blank"
      aria-label="Telegram"
    >
      <TelegramIcon />
    </a>

    <a
      href="https://instagram.com/gora.studio"
      target="_blank"
      aria-label="Instagram"
    >
      <InstagramIcon />
    </a>

    <a
      href="https://api.whatsapp.com/send/?phone=79052024686&text&app_absent=0"
      target="_blank"
      aria-label="Whatsapp"
    >
      <WhatsappIcon />
    </a>

    <a
      href="https://www.facebook.com/gora.studio.soft/"
      target="_blank"
      aria-label="Facebook"
    >
      <FacebookIcon />
    </a>
  </div>
</template>

<script>
import TelegramIcon from '@/components/icons/Telegram.vue';
import InstagramIcon from '@/components/icons/Instagram.vue';
import WhatsappIcon from '@/components/icons/Whatsapp.vue';
import FacebookIcon from '@/components/icons/Facebook.vue';

export default {
  components: {
    TelegramIcon,
    InstagramIcon,
    WhatsappIcon,
    FacebookIcon,
  },
};
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  margin: 0 -8px;

  > * {
    margin: 0 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include themed() {
      background: t('social-bg');
    }
  }
}
</style>
