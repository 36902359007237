<template>
  <div class="contacts">
    <!-- <a href="tel:+74951310856">
      +7 (495) 131-08-56
    </a>
    <a href="tel:+78126490486">
      +7 (812) 649-04-86
    </a> -->
    <a href="mailto:hi@gora.studio">
      hi@gora.studio
    </a>
    <a
      :href="mapsUrl"
      target="_blank"
    >
      {{ $t('footer.address') }}
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    mapsUrl() {
      let lang;
      if (navigator.languages && navigator.languages.length) {
        [lang] = navigator.languages;
      } else {
        lang = navigator.language;
      }

      if (lang.includes('ru')) {
        return 'https://yandex.ru/maps/-/CCU5QXhVLB';
      }
      return 'https://goo.gl/maps/3g1PHXmPEhKQg2q97';
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  flex-direction: column;
  margin: -8px;
  margin-bottom: 45px;
  width: 200px;

  > * {
    margin: 8px;
  }
}
</style>
