<template>
  <div id="fidgets">
    <div
      class="fidget-wrapper"
      v-for="(fidget, index) in fidgets"
      :key="index"
      :style="{
        transform: translateFidget(fidget),
        transition: `transform ${fidget.delay}s`,
      }"
    >
      <img
        :src="fidget.src"
        @click="pushFidget(index, 5)"
        :style = "{
          animationDuration: `${18 + index/2}s`
        }"
        aria-hidden="true"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import fidgetPositions from './fidgetPositions';

export default {
  data() {
    return {
      fidgets: fidgetPositions,
    };
  },
  mounted() {
    this.fidgets.forEach((fidget, i) => {
      fidget.src = require(`@/assets/hero/fidgets/${i}.webp`); // eslint-disable-line
      fidget.delay = Math.random() * 4;
    });

    if (!this.$store.state.desktop) {
      this.fidgets.splice(13, 1);
    }

    setTimeout(() => {
      this.fidgets.forEach((_, i) => {
        this.pushFidget(i, 0.5);
      });
    });
  },
  methods: {
    translateFidget(fidget) {
      return `translate(${fidget.x}vw, ${fidget.y}vh)`;
    },
    pushFidget(i, force) {
      this.fidgets[i].x += ((Math.random() - 0.5) * 4) * force;
      this.fidgets[i].y += ((Math.random() - 0.5) * 4) * force;
      this.fidgets[i].delay = Math.random() * 2 + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
#fidgets {
  z-index: 10;
  position: absolute;
  overflow: hidden;

  .fidget-wrapper {
    position: absolute;
    cursor: pointer;
    user-select: none;
    transition-timing-function: ease-out;

    img {
      position: relative;
      animation: rotating 20s linear infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(-45deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  @keyframes rotating {
    from {
      transform: rotate(-45deg) scale(.7);
    }
    to {
      transform: rotate(45deg) scale(.7);
    }
  }
}
</style>
