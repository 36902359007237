<!-- eslint-disable -->
<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.362 3.13086C16.6081 3.13086 16.8542 2.92578 16.8542 2.63867V1.32617C16.8542 1.08008 16.6081 0.833984 16.362 0.833984H1.30469C1.01758 0.833984 0.8125 1.08008 0.8125 1.32617V2.63867C0.8125 2.92578 1.01758 3.13086 1.30469 3.13086H16.362ZM18.6953 9.69336C18.9414 9.69336 19.1875 9.48828 19.1875 9.20117V7.88867C19.1875 7.64258 18.9414 7.39648 18.6953 7.39648H1.30469C1.01758 7.39648 0.8125 7.64258 0.8125 7.88867V9.20117C0.8125 9.48828 1.01758 9.69336 1.30469 9.69336H18.6953ZM16.362 16.2559C16.6081 16.2559 16.8542 16.0508 16.8542 15.7637V14.4512C16.8542 14.2051 16.6081 13.959 16.362 13.959H1.30469C1.01758 13.959 0.8125 14.2051 0.8125 14.4512V15.7637C0.8125 16.0508 1.01758 16.2559 1.30469 16.2559H16.362Z"
      fill="currentColor"
    />
  </svg>
</template>
