<template>
  <div class="footer">
    <div class="main">
      <div class="logo-wrap">
        <router-link
          class="logo"
          :to="{ name: 'Home' }"
        />

        <span>© 2016—{{ year }} GORA Studio</span>
        <router-link
          :to='{ name: "Policy" }'
          class="clr-inherit"
          target='_blank'
        >
          {{ $t('footer.policy') }}
        </router-link>
      </div>

      <div class="sections">
        <Contacts
          class="section"
        />

        <div class="section navigation">
          <router-link
            :to="{ name: 'Projects' }"
          >
            {{ $t('portfolio') }}
          </router-link>
          <router-link
            :to="{ name: 'Team' }"
          >
            {{ $t('team') }}
          </router-link>
          <router-link
            :to="{ name: 'Tech' }"
          >
            {{ $t('tech') }}
          </router-link>
          <router-link
            :to="{ name: 'Career' }"
          >
            {{ $t('jobs') }}
          </router-link>
          <router-link
            :to="{ name: 'Contacts' }"
          >
            {{ $t('contacts') }}
          </router-link>
        </div>

        <div>
          <SocialLinks />
        </div>
      </div>
    </div>
    <div class="appendix">
      {{ $t('footer.appendix') }}
    </div>
  </div>
</template>

<script>
import SocialLinks from './SocialLinks.vue';
import Contacts from './Contacts.vue';

export default {
  components: {
    SocialLinks,
    Contacts,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  @include themed() {
    background: t('footer-bg');
  }

  .main {
    display: flex;
    justify-content: space-between;
    padding: 38.5px 60px 0px;

    .logo-wrap {
      flex-grow: 1;
      position: relative;
      color: $dark-gray;
      display: flex;
      flex-direction: column;

      > * {
        margin-bottom: 16px;
        width: 200px;
      }

      .logo {
        position: relative;
        width: 100px;
        height: 38.5px;
        background-size: cover;

        @include themed() {
          background-image: t('logo');
        }
      }
    }

    .sections {
      display: flex;
      justify-content: space-between;
      flex-grow: 2;

      > * {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      .section {
        margin: -8px;
        margin-bottom: 45px;

        > * {
          margin: 8px;
        }
      }

      .navigation {
        height: 150px;
        width: 300px;

        > * {
          width: 150px;
        }
      }
    }
  }

  .appendix {
    color: $dark-gray;
    padding: 38.5px 60px;
    border-top: 1px solid rgba($light-blue, .1);
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .footer {
    .main {
      flex-direction: column-reverse;
      padding: 38.5px 20px 0px;

      .sections {
        flex-direction: column;
        margin-bottom: 45px;
      }
    }

    .appendix {
      padding: 32px 20px 72px;
    }
  }
}
</style>
