<!-- eslint-disable -->
<template>
  <svg
    width="11"
    height="20"
    viewBox="0 0 11 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.38409 11.1545V20H6.76819V11.1545H10.1523L10.9983 8.1752H6.76819V5.70983C6.76819 4.34234 6.84264 4.00866 7.04568 3.672C7.16304 3.47111 7.34881 3.30757 7.57699 3.20425C7.95939 3.024 8.60068 2.95995 10.1523 2.95995H11V0.129622C10.1151 0.0178981 9.39425 -0.0208325 8.83756 0.01045C7.43316 0.0894012 6.62944 0.272628 5.98308 0.578005C5.16406 0.95889 4.49427 1.54804 4.06091 2.26875C3.62098 2.99719 3.38409 3.71967 3.38409 5.71132V8.1752H0V11.1545H3.38409Z"
      fill="currentColor"
    />
  </svg>
</template>
