export default [
  { // 0
    x: 77.7,
    y: 74,
  },
  { // 1
    x: 10.4,
    y: 25,
  },
  { // 2
    x: 27,
    y: 27,
  },
  { // 3
    x: 74,
    y: 24,
  },
  { // 4
    x: 80,
    y: 20,
  },
  { // 5
    x: 7.1,
    y: 32,
  },
  { // 6
    x: 17,
    y: 34,
  },
  { // 7
    x: 29,
    y: 50,
  },
  { // 8
    x: 80,
    y: 44,
  },
  { // 9
    x: 4,
    y: 46,
  },
  { // 10
    x: 20,
    y: 49,
  },
  { // 11
    x: 80,
    y: 40,
  },
  { // 12
    x: 12,
    y: 70,
  },
  { // 13
    x: 15,
    y: 60,
  },
  { // 14
    x: 30,
    y: 58,
  },
  { // 15
    x: 39,
    y: 69,
  },
  { // 16
    x: 71.5,
    y: 60,
  },
  { // 17
    x: 76,
    y: 50,
  },
  { // 18
    x: 64,
    y: 60,
  },
  { // 19
    x: 86,
    y: 60,
  },
  { // 20
    x: 66,
    y: 40,
  },
];
