import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home/Index.vue';

import projects from './projects';

const Contacts = () => import(/* webpackChunkName: "page-Contacts" */ '../views/Contacts/Index.vue');
const Projects = () => import(/* webpackChunkName: "page-Projects" */ '../views/Projects/Index.vue');
const Team = () => import(/* webpackChunkName: "page-Team" */ '../views/Team/Index.vue');
const Tech = () => import(/* webpackChunkName: "page-Tech" */ '../views/Tech/Index.vue');
const Career = () => import(/* webpackChunkName: "page-Career" */ '../views/Career/Index.vue');
const Policy = () => import(/* webpackChunkName: "page-Policy" */ '../views/Policy.vue');

const CrossStitchPlannerLegal = () => import('../views/CrossStitchPlannerLegal.vue');

const LongLayout = () => import('../views/Project/Layouts/Long.vue');

let routes = [
  {
    path: '/:lang?',
    name: 'Home',
    component: Home,
    props: true,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/projects/:projectId',
    name: 'Project',
    component: LongLayout,
    props: true,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
  },
  {
    path: '/tech',
    name: 'Tech',
    component: Tech,
  },
  {
    path: '/career',
    name: 'Career',
    component: Career,
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: Policy,
  },
  {
    path: '/ru/cross-stitch_agreement',
    name: 'CrossStitchPlannerLegal',
    component: CrossStitchPlannerLegal,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
];

routes = routes.concat(projects);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo({
      top: 0,
    });
  },
});

export default router;
