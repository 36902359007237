import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

import ru from './ru.json';

export const SUPPORT_LOCALES = ['ru', 'en', 'de', 'fr', 'zh'];

export default createI18n({
  locale: 'ru',
  messages: { ru },
});

export function setI18nLanguage(i18n, key) {
  const i18nInstance = i18n.global ?? i18n;
  i18nInstance.locale = key;
  document.documentElement.setAttribute('lang', key);
}

export async function loadLocaleMessages(i18n, locale) {
  const i18nInstance = i18n.global ?? i18n;
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */
    `./${locale}.json`
  );

  i18nInstance.setLocaleMessage(locale, messages.default);

  return nextTick();
}
