<template>
  <div
    ref="wrapper"
    class="lazy-component"
    :class="{
      loaded: visible
    }"
  >
    <slot
      v-if="visible"
    ></slot>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  delay: {
    type: Number,
    required: false,
  },
});

const wrapper = ref(null);
const visible = ref(false);

const { stop } = useIntersectionObserver(
  wrapper,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      stop();
      visible.value = true;
    }
  },
  {
    rootMargin: '100% 0px 0px 0px',
  },
);

if (props.delay) {
  setTimeout(() => {
    stop();
    visible.value = true;
  }, props.delay);
}
</script>

<style scoped lang="scss">
.lazy-component {
  position: relative;
  min-height: 600px;

  &.loaded {
    min-height: 0;
  }
}
</style>
