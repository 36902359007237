const Arround = () => import('../views/Project/Arround.vue');
const PeekUp = () => import('../views/Project/PeekUp.vue');
const CostM = () => import('../views/Project/CostM.vue');
const Remmy = () => import('../views/Project/Remmy.vue');
const CrossStitchPlanner = () => import('../views/Project/CrossStitchPlanner.vue');
const FinTeam = () => import('../views/Project/FinTeam.vue');
const Architeque = () => import('../views/Project/Architeque.vue');
const Nwt = () => import('../views/Project/Nwt.vue');

export default [
  {
    path: '/projects/arround',
    name: 'arround',
    component: Arround,
  },
  {
    path: '/projects/peek_up',
    name: 'peek_up',
    component: PeekUp,
  },
  {
    path: '/projects/costm',
    name: 'costm',
    component: CostM,
  },
  {
    path: '/projects/remmy',
    name: 'remmy',
    component: Remmy,
  },
  {
    path: '/projects/cross_stitch_planner',
    name: 'cross_stitch_planner',
    component: CrossStitchPlanner,
  },
  {
    path: '/projects/fin_team',
    name: 'fin_team',
    component: FinTeam,
  },
  {
    path: '/projects/architeque',
    name: 'architeque',
    component: Architeque,
  },
  {
    path: '/projects/wine_food_pairing',
    name: 'wine_food_pairing',
    component: Nwt,
  },
];
