<template>
  <Navbar />
  <router-view />
  <Footer></Footer>
  <ContactForm/>
</template>

<script>
import { mapState } from 'vuex';

import { SUPPORT_LOCALES, loadLocaleMessages, setI18nLanguage } from '@/localization';
import Navbar from '@/components/Navbar/Index.vue';
import Footer from '@/components/Footer.vue';
import ContactForm from '@/components/ContactForm/Index.vue';

export default {
  components: {
    Navbar,
    Footer,
    ContactForm,
  },
  computed: {
    ...mapState(['modalOpen']),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });

    this.$router.isReady().then(async () => {
      let hostLang = this.$route.query.hl;
      if (!SUPPORT_LOCALES.includes(hostLang)) {
        hostLang = 'ru';
      }

      await loadLocaleMessages(this.$i18n, hostLang);
      setI18nLanguage(this.$i18n, hostLang);
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    modalOpen() {
      if (this.modalOpen) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    },
  },
  methods: {
    onResize() {
      this.$store.commit('setDesktop', window.innerWidth > 1024);
    },
  },
};
</script>

<style lang="scss">
@import "reset-css";
@import "./scss/style";

#app {
  font: $font-text-16;
  padding-top: 100px;

  @include themed() {
    background: t('bg');
    color: t('clr-text');
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  #app {
    padding-top: 80px;
  }
}
</style>
