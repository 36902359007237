<template>
  <transition name="dissolve">
    <div
      v-if="contactFormOpen"
      class="bg"
      @click.self="close"
      aria-hidden="true"
    />
  </transition>
  <transition name="slide">
    <div
      v-if="contactFormOpen"
      class="modal"
    >
      <Form />
      <button
        class="close"
        @click="close"
      >
        <CrossIcon />
      </button>
    </div>
  </transition>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState } from 'vuex';
import CrossIcon from '@/components/icons/Cross.vue';

export default {
  name: 'ContactForm',
  components: {
    Form: defineAsyncComponent(() => import('./Form.vue')),
    CrossIcon,
  },
  computed: {
    ...mapState(['contactFormOpen']),
  },
  methods: {
    close() {
      this.$store.commit('setContactFormOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  backdrop-filter: blur(20px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2500;

  @include gpu();
}

.modal {
  z-index: 3000;
  position: fixed;
  right: 0;
  top: 0;
  width: 650px;
  height: 100%;
  overflow: scroll;
  padding: 64px 114px 64px 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @include themed() {
    background: t('bg');
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 10px;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @include themed() {
        color: t('clr-text');
      }
    }

    @include themed() {
      background: t('close-btn-bg')
    }
  }
}

.slide-enter-active, .slide-leave-active {
  transition: transform .5s ease;
}

.slide-enter-to, .slide-leave {
  transform: translateX(0);
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(100%);
}

.dissolve-enter-active, .dissolve-leave-active {
  transition: opacity .5s ease;
}

.dissolve-enter-to, .dissolve-leave {
  opacity: 1;
}

.dissolve-enter-from, .dissolve-leave-to {
  opacity: 0;
}

@media screen and (max-width: $mobile-breakpoint) {
  .bg {
    display: none;
  }

  .modal {
    width: 100%;
    box-sizing: border-box;
    padding: 96px 20px 64px 20px;
  }
}
</style>
