<template>
  <div class="select">
    <button
      class="lang selected"
      @click="toggle"
    >
      <img
        class="lang-icon"
        :src="selected.icon"
        alt=""
      >
      {{ selected.name }}
      <ChevronIcon
        class="chevron"
        :class="{ expanded }"
      />
    </button>

    <div
      class="dropdown"
      :class="{ expanded }"
    >
      <button
        v-for="lang in otherLangs"
        :key="lang.key"
        class="lang"
        @click="select(lang.key)"
      >
        <img
          class="lang-icon"
          :src="lang.icon"
          alt=""
        >
        {{ lang.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ChevronIcon from '@/components/icons/Chevron.vue';
import { setI18nLanguage, loadLocaleMessages } from '@/localization';

export default {
  components: {
    ChevronIcon,
  },
  data() {
    return {
      expanded: false,
      langs: [
        {
          key: 'ru',
          name: 'Русский',
          icon: require('@/assets/icons/emoji/48x48/bear face.png'),
        },
        {
          key: 'en',
          name: 'English',
          icon: require('@/assets/icons/emoji/48x48/guard 1.png'),
        },
        {
          key: 'fr',
          name: 'France',
          icon: require('@/assets/icons/emoji/48x48/croissant.png'),
        },
        {
          key: 'de',
          name: 'Deutsche',
          icon: require('@/assets/icons/emoji/48x48/beer mug.png'),
        },
        {
          key: 'zh',
          name: 'China',
          icon: require('@/assets/icons/emoji/48x48/panda face.png'),
        },
      ],
    };
  },
  computed: {
    ...mapState(['theme']),
    selected() {
      return this.langs.filter((lang) => lang.key === this.$i18n.locale)[0];
    },
    otherLangs() {
      return this.langs.filter((lang) => lang.key !== this.$i18n.locale);
    },
  },
  watch: {
    theme: {
      handler(theme) {
        if (theme === 'dark') {
          this.langs[1].icon = require('@/assets/icons/emoji/48x48/guard 1.png');
        } else {
          this.langs[1].icon = require('@/assets/icons/emoji/48x48/guard 5.png');
        }
      },
      immediate: true,
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    async select(key) {
      await loadLocaleMessages(this.$i18n, key);
      setI18nLanguage(this.$i18n, key);
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  z-index: 10;

  .lang {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1px 10px;

    .lang-icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    .chevron {
      transform: rotate(90deg);
      margin-left: 6px;
      transition: transform .5s;

      &.expanded {
        transform: rotate(90deg) scaleX(-1);
      }
    }
  }

  .selected {
    z-index: 1;

    @include themed() {
      background: t('bg');
    }
  }

  .dropdown {
    position: absolute;
    transition: opacity .5s;
    opacity: 0;
    pointer-events: none;

    @include themed() {
      background: t('bg');
    }

    &.expanded {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
