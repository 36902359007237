import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    desktop: false,
    theme: null,
    locale: 'ru',
    modalOpen: false,
    contactFormOpen: false,
    contactFormContents: null,
  },
  mutations: {
    setDesktop(state, isDesktop) {
      state.desktop = isDesktop;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setModalOpen(state, open) {
      state.modalOpen = open;
    },
    setContactFormOpen(state, open) {
      state.modalOpen = open;
      state.contactFormOpen = open;
    },
    saveContactForm(state, contactForm) {
      state.contactFormContents = contactForm;
    },
  },
  plugins: [createPersistedState({
    paths: [
      'theme',
      'locale',
      'contactFormContents',
    ],
  })],
});
