<template>
  <div class="home">
    <Hero />

    <Services />

    <LazyComponent :delay="5000">
      <Stats />
    </LazyComponent>

    <LazyComponent :delay="8000">
      <GoraAR />
    </LazyComponent>

    <LazyComponent :delay="8000">
      <Publications />
    </LazyComponent>

    <LazyComponent
      class="tech"
      :delay="8000"
    >
      <Tech />
    </LazyComponent>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import LazyComponent from '@/components/LazyComponent.vue';
import Hero from './Hero/Index.vue';

export default {
  name: 'Home',
  components: {
    LazyComponent,
    Hero,
    Services: defineAsyncComponent(() => import(/* webpackChunkName: "component-Services" */ './Services/Index.vue')),
    Stats: defineAsyncComponent(() => import(/* webpackChunkName: "component-Stats" */ './Stats/Index.vue')),
    GoraAR: defineAsyncComponent(() => import(/* webpackChunkName: "component-GoraAR" */ './GoraAR/Index.vue')),
    Publications: defineAsyncComponent(() => import(/* webpackChunkName: "component-Publications" */ './Publications/Index.vue')),
    Tech: defineAsyncComponent(() => import(/* webpackChunkName: "component-Tech" */ './Tech.vue')),
  },
  mounted() {
    const locales = ['ru', 'en', 'fr', 'de', 'zh'];
    if (locales.includes(this.$route.params.lang)) {
      this.$i18n.locale = this.$route.params.lang ?? 'ru';
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: -100px;

  @include themed() {
    background: t('bg');
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .home {
    margin-top: -80px;
  }

  .tech {
    display: none;
  }
}
</style>
