<!-- eslint-disable -->
<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.931254 7.01823L18.668 0.0848573C19.4912 -0.216661 20.2102 0.288461 19.9435 1.55049L19.945 1.54893L16.925 15.9737C16.7012 16.9963 16.1018 17.245 15.2632 16.7632L10.6642 13.3268L8.44599 15.4934C8.20071 15.7421 7.99376 15.9519 7.51853 15.9519L7.84506 11.2069L16.3685 3.40001C16.7395 3.06896 16.2857 2.88246 15.7967 3.21195L5.2635 9.93551L0.722766 8.49941C-0.262949 8.18235 -0.284411 7.50004 0.931254 7.01823Z"
      fill="currentColor"
    />
  </svg>
</template>
