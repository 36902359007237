import throttle from 'lodash.throttle';

export default throttle((event) => {
  const guyElem = document.getElementById('guy');

  const x = event.clientX;
  const y = event.clientY;

  const guyX = guyElem.offsetLeft;
  const guyY = guyElem.offsetTop;

  const constrain = 500;

  const xRot = -(y - guyY - guyElem.height / 2) / constrain;
  const yRot = (x - guyX) / constrain;

  guyElem.style.transform = `translateX(-50%) 
  translateZ(-10px)
  perspective(200px) 
  rotateX(${xRot / 1.5}deg) 
  rotateY(${yRot}deg)`;
}, 50);
