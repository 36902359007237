<template>
  <div
    v-if="show && !$store.state.desktop"
    class="mobile-menu"
  >
    <div class="container">
      <NavigationLinks />
      <LanguageSelector />
      <ThemeSwitch />
      <Contacts class="contacts" />
    </div>
  </div>
</template>

<script>
import NavigationLinks from './NavigationLinks.vue';
import ThemeSwitch from './ThemeSwitch.vue';
import LanguageSelector from './LanguageSelector.vue';
import Contacts from '../Contacts.vue';

export default {
  components: {
    NavigationLinks,
    ThemeSwitch,
    LanguageSelector,
    Contacts,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
      this.$store.commit('setModalOpen', true);
    },
    close() {
      this.show = false;
      this.$store.commit('setModalOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  z-index: 1000;
  position: fixed;
  top: $mobile-navbar;
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-bottom: 1px solid $light-blue;

  @include themed() {
    background: t('bg');
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-bottom: calc($mobile-navbar + 16px);
    align-items: center;

    > * {
      margin-bottom: 32px;
    }

    .contacts {
      margin-top: 36px;
      text-align: center;
    }
  }
}
</style>
