<template>
  <div
    class="bg"
  >
    <img
      src="@/assets/hero/guy.webp"
      id="guy"
      ref="guy"
      alt=""
    >
  </div>
</template>

<script>
import rotateGuy from './rotateGuy';

export default {
  mounted() {
    if (this.$store.state.desktop) {
      window.addEventListener('mousemove', rotateGuy);
    }
  },
  unmounted() {
    window.removeEventListener('mousemove', rotateGuy);
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: absolute;
  transform: translateZ(-10px);

  > * {
    position: absolute;
  }

  #guy {
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
}
</style>
