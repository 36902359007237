<template>
  <div class="nav-wrapper">
    <nav>
      <router-link
        class="logo"
        :to="{ name: 'Home' }"
        :class="{ hidden: !linksHidden }"
        aria-label="Gora Studio logo"
      />
      <div class="language-selector-wrapper">
        <LanguageSelector
          class="language-selector"
          :class="{ hidden: !linksHidden }"
        />
      </div>

      <div class="flex-spacer"></div>

      <NavigationLinks
        class="navigation-links"
        :class="{ hidden: linksHidden }"
      />

      <button
        class="toggle-links"
        @click="linksHidden = !linksHidden"
        :class="{ open: linksHidden }"
      >
        <ChevronIcon
          class="dots"
        />
      </button>

      <ThemeSwitch
        class="theme-switch"
      />

      <HamburgerIcon
        v-if="!menuOpen"
        class="hamburger"
        @click="openMenu"
      />
      <CrossIcon
        v-else
        class="hamburger"
        @click="closeMenu"
      />
    </nav>
  </div>

  <transition name="slide">
    <MobileMenu ref="mobileMenu" />
  </transition>
</template>

<script>
import HamburgerIcon from '@/components/icons/Hamburger.vue';
import CrossIcon from '@/components/icons/Cross.vue';
import ChevronIcon from '@/components/icons/Chevron.vue';
import NavigationLinks from './NavigationLinks.vue';
import ThemeSwitch from './ThemeSwitch.vue';
import LanguageSelector from './LanguageSelector.vue';
import MobileMenu from './MobileMenu.vue';

export default {
  name: 'Navbar',
  components: {
    HamburgerIcon,
    CrossIcon,
    ChevronIcon,
    ThemeSwitch,
    LanguageSelector,
    NavigationLinks,
    MobileMenu,
  },
  data() {
    return {
      menuOpen: false,
      linksHidden: true,
    };
  },
  watch: {
    $route() {
      this.closeMenu();
    },
  },
  methods: {
    openMenu() {
      this.menuOpen = true;
      this.$refs.mobileMenu.open();
    },
    closeMenu() {
      this.menuOpen = false;
      this.$refs.mobileMenu.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;

  nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 60px;
    box-sizing: border-box;
    font: $font-text-18;
    // box-shadow: 0 0 32px 32px $bg-blue;

    @include themed() {
      background: t('bg');
    }

    .logo {
      position: relative;
      width: 122px;
      height: 47px;
      background-repeat: no-repeat;
      background-position: top left;

      @include themed() {
        background-image: t('logo');
      }
    }

    .language-selector-wrapper {
      position: absolute;
      margin-left: 122px;
      margin-bottom: 28px;

      .language-selector {
        position: fixed;
        margin-left: 32px;
      }
    }

    .toggle-links {
      display: none;
    }

    .theme-switch {
      margin-left: 64px;
    }

    .hamburger {
      display: none;
    }
  }
}

@media screen and (max-width: 1286px) {
  .nav-wrapper {
    nav {
      overflow: hidden;

      .logo, .navigation-links {
        transition: transform 2s, max-width .5s;
      }

      .logo {
        max-width: 122px;
      }

      .logo {
        &.hidden {
          max-width: 47px;
        }
      }

      .language-selector {
        transition: opacity .5s;

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }
      }

      .navigation-links {
        position: absolute;
        right: 192px;

        &.hidden {
          transform: translate(calc(100vw - 266px));
          pointer-events: none;
        }
      }

      .toggle-links {
        display: block;
        height: 100%;
        width: 32px;
        z-index: 10;
        padding: 0 206px 0 10px;
        transform: translate(206px);

        @include themed() {
          background: t('bg');
        }

        svg {
          color: $light-blue;
          transition: transform 1s;
          height: 100%;
          width: 16px;
          cursor: pointer;
        }

        &.open {
          svg {
            transform: scaleX(-1);
          }
        }
      }

      .theme-switch {
        z-index: 10;
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .nav-wrapper {
    nav {
      height: 80px;
      padding: 0 25px;

      .logo.hidden {
        max-width: 122px;
      }

      .navigation-links, .theme-switch, .language-selector, .toggle-links {
        display: none;
      }

      .hamburger {
        display: block;
        padding: 8px;
        width: 20px;
      }
    }
  }
}

.slide-enter-active, .slide-leave-active {
  transition: transform .5s ease;
}

.slide-enter-to, .slide-leave {
  transform: translateY(0);
}

.slide-enter-from, .slide-leave-to {
  transform: translateY(-100%);
}
</style>
