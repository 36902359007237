<!-- eslint-disable -->
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20L1.40069 15.0306C0.499964 13.5021 0.0257258 11.7629 0.0257258 9.97634C0.0257258 4.47537 4.50595 0 10.0129 0C15.5198 0 20 4.47537 20 9.97634C20 15.4773 15.5198 19.9527 10.0129 19.9527C8.29697 19.9527 6.61674 19.5139 5.13034 18.6808L0 20ZM5.39262 16.8658L5.69832 17.0523C6.99429 17.8426 8.48627 18.2604 10.0129 18.2604C14.5857 18.2604 18.3058 14.5442 18.3058 9.97634C18.3058 5.40852 14.5857 1.69232 10.0129 1.69232C5.44012 1.69232 1.71988 5.40852 1.71988 9.97634C1.71988 11.5679 2.17272 13.1135 3.02934 14.446L3.23521 14.7662L2.4286 17.628L5.39262 16.8658Z"
      fill="currentColor"
    />
    <path
      d="M7.20677 5.33334L6.55872 5.29805C6.35517 5.28695 6.15551 5.3549 6.00166 5.4884C5.68749 5.76093 5.18514 6.2878 5.03085 6.97438C4.80075 7.99811 5.15634 9.25168 6.07664 10.5052C6.99695 11.7588 8.71199 13.7645 11.7447 14.6211C12.722 14.8972 13.4908 14.7111 14.0839 14.3321C14.5537 14.0319 14.8775 13.5501 14.9942 13.0054L15.0977 12.5227C15.1306 12.3692 15.0526 12.2135 14.9099 12.1479L12.7197 11.1394C12.5776 11.074 12.409 11.1154 12.3133 11.2391L11.4535 12.3526C11.3886 12.4367 11.2774 12.47 11.177 12.4348C10.5882 12.2281 8.6158 11.4029 7.53355 9.3205C7.48662 9.23018 7.49829 9.12055 7.56486 9.04352L8.38659 8.09394C8.47055 7.99698 8.49175 7.86065 8.44131 7.74282L7.49722 5.53648C7.44696 5.41902 7.33433 5.34029 7.20677 5.33334Z"
      fill="currentColor"
    />
  </svg>
</template>
